/* ======================================================================== */
/* 26. fluidTop */
/* ======================================================================== */
.top-offset
  &.top-offset_xsmall
    @include fluid('top', $distance-min-xsmall, $distance-max-xsmall)
  &.top-offset_small
    @include fluid('top', $distance-min-small, $distance-max-small)
  &.top-offset_medium
    @include fluid('top', $distance-min-medium, $distance-max-medium)
  &.top-offset_large
    @include fluid('top', $distance-min-large, $distance-max-large)
  &.top-offset_xlarge
    @include fluid('top', $distance-min-xlarge, $distance-max-xlarge)
