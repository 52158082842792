/* ======================================================================== */
/* 74. tags */
/* ======================================================================== */
.tagcloud, .widget .tagcloud
	a
		display: inline-block
		font-size: 13px
		font-weight: 500
		line-height: 1
		background-color: transparent
		padding: 8px 14px
		margin-bottom: 6px
		margin-right: 4px
		border-radius: 100px
		background-color: $color-light-3
		&:hover
			border-color: $color-dark-1
	ul
		+reset-ul
		li
			display: inline-block
			margin-bottom: 0
