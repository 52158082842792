/* ======================================================================== */
/* 93. sliderMenu */
/* ======================================================================== */
.slider-menu
  width: 100%
  height: 100%
  // max-width: 800px
.slider-menu__header
  padding-top: 2em
  padding-left: 2em
.slider-menu__slide
  height: auto
  // opacity: 0.2
  transition: opacity 0.3s ease
  *
    pointer-events: none !important
  &.swiper-slide-active
    opacity: 1
    pointer-events: auto
    *
      pointer-events: auto !important
  &:not(.swiper-slide-active)
    .slider-menu__header
      a
        // opacity: 0
.slider-menu__wrapper-image
  display: block
  img
    transform: scale(1.1)
    transform-origin: center center


@media screen and (max-width: $md)
  .slider-menu__header
    padding-top: 1em
    padding-left: 1em
  .slider-menu
    margin-left: calc(var(--gutter-horizontal) * -1)
    margin-right: calc(var(--gutter-horizontal) * -1)
    width: auto
