/* ======================================================================== */
/* 71. author */
/* ======================================================================== */
.author
	display: flex
	width: 100%
.author__name
	font-size: 13px
	line-height: 1.2
	font-weight: 500
	color: $color-gray-3
.author__avatar
	overflow: hidden
	border-radius: 50%
	margin-right: 1em
