/* ======================================================================== */
/* 11. counter */
/* ======================================================================== */
.counter_circle
	position: relative
	display: inline-flex
	flex-direction: column
	justify-content: center
	// width: 100%
	// height: 0
	// padding-bottom: 100%
	@include fluid('width', 220, 260)
	@include fluid('height', 220, 260)
	// width: 280px
	// height: 280px
	// max-width: 100%
	// max-height: 100%
	.svg-circle
		stroke-width: 2px
		stroke: $color-border-dark
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
// .counter__inner
// 	display: flex
// 	position: absolute
// 	top: 0
// 	left: 0
// 	width: 100%
// 	height: 100%
// 	flex-direction: column
// 	align-items: center
// 	justify-content: center
.counter__number
	position: relative
	z-index: 50
	// font-family: $font-secondary
	// @include fluid-type(43, 104)
	// font-weight: 200
	// line-height: 1
	
.counter__label
	position: relative
	z-index: 50
	margin-top: 8px
	white-space: nowrap
	// color: $brown-grey
	line-height: 1.2 !important
	// @include fluid-type(14, 18)
