\:root
	/* Bootstrap */
	--bs-gutter-x: 20px
	--bs-gutter-y: 20px
	/* Fonts */
	--font-primary: 'Inter', sans-serif
	/* Dark Colors */
	--color-dark-1: #000000
	--color-dark-2: #181818
	--color-dark-3: #333333
	--color-dark-4: #555555
	/* Light Colors */
	--color-light-1: #ffffff
	--color-light-2: #fafafa
	--color-light-3: #f8f8f8
	--color-light-4: #f2f2ed
	--color-gray-1: #b0b0b0
	--color-gray-2: #cccccc
	--color-gray-3: #888888
	--color-gray-4: #e8e8e8
	/* Portfolio Projects Colors */
	--color-portfolio-1: #f1f6f6
	--color-portfolio-2: #262626
	--color-portfolio-3: #f6f6f1
	--color-portfolio-4: #d7d7d9
	--color-portfolio-5: #dededb
	--color-portfolio-6: #f7f6f3
	--color-portfolio-7: #f0f0f0
	--color-portfolio-8: #eaeaea
	--color-portfolio-9: #f1f0f0
	--color-portfolio-10: #eeece6
	--color-portfolio-11: #fbf7f6
	--color-portfolio-12: #e9eaec
	/* Borders Colors */
	--color-border-dark: rgba(136, 136, 136, .3)
	--color-border-light: rgba(136, 136, 136, .3)
	/* XXL heading */
	--xxl-max-font-size: 126
	--xxl-min-font-size: 54
	--xxl-line-height: 1.0
	--xxl-letter-spacing: -4
	--xxl-color-dark: #555555
	--xxl-color-light: #ffffff
	/* XL heading */
	--xl-max-font-size: 102
	--xl-min-font-size: 46
	--xl-line-height: 1.18
	--xl-letter-spacing: -2
	--xl-color-dark: #555555
	--xl-color-light: #ffffff
	/* h1 heading */
	--h1-max-font-size: 80
	--h1-min-font-size: 40
	--h1-line-height: 1.20
	--h1-letter-spacing: -1
	--h1-color-dark: #555555
	--h1-color-light: #ffffff
	/* h2 heading */
	--h2-max-font-size: 52
	--h2-min-font-size: 30
	--h2-line-height: 1.28
	--h2-letter-spacing: 0
	--h2-color-dark: #555555
	--h2-color-light: #ffffff
	/* h3 heading */
	--h3-max-font-size: 32
	--h3-min-font-size: 24
	--h3-line-height: 1.5
	--h3-letter-spacing: 0
	--h3-color-dark: #555555
	--h3-color-light: #ffffff
	/* h4 heading */
	--h4-max-font-size: 20
	--h4-min-font-size: 18
	--h4-line-height: 1.8
	--h4-letter-spacing: 0
	--h4-color-dark: #555555
	--h4-color-light: #ffffff
	/* h5 heading */
	--h5-max-font-size: 18
	--h5-min-font-size: 16
	--h5-line-height: 1.8
	--h5-letter-spacing: 0
	--h5-color-dark: #555555
	--h5-color-light: #ffffff
	/* h6 heading */
	--h6-max-font-size: 16
	--h6-min-font-size: 14
	--h6-line-height: 1.8
	--h6-letter-spacing: 0
	--h6-color-dark: #555555
	--h6-color-light: #ffffff
	/* Subheading */
	--subheading-max-font-size: 13
	--subheading-min-font-size: 10
	--subheading-line-height: 1.0
	--subheading-letter-spacing: 2
	--subheading-color-dark: #555555
	--subheading-color-light: #b0b0b0
	/* Paragraph */
	--paragraph-max-font-size: 16
	--paragraph-min-font-size: 16
	--paragraph-line-height: 1.8
	--paragraph-letter-spacing: 0
	--paragraph-color-dark: #555555
	--paragraph-color-light: #ffffff
	/* Blockquote */
	--blockquote-max-font-size: 24
	--blockquote-min-font-size: 16
	--blockquote-letter-spacing: 0
	--blockquote-color-dark: #555555
	--blockquote-color-light: #ffffff
	/* Dropcap */
	--dropcap-max-font-size: 90
	--dropcap-min-font-size: 60
	--dropcap-color-dark: #111111
	--dropcap-color-light: #ffffff
	/* Fluid paddings & margins (min values) */
	--distance-min-xsmall: 30
	--distance-min-small: 40
	--distance-min-medium: 50
	--distance-min-large: 50
	--distance-min-xlarge: 90
	/* Fluid paddings & margins (max values) */
	--distance-max-xsmall: 60
	--distance-max-small: 80
	--distance-max-medium: 100
	--distance-max-large: 160
	--distance-max-xlarge: 240
	/* Container & page gutters */
	--gutter-horizontal: 120px
	--gutter-vertical: 100px
	@media screen and (max-width: $xxl)
		--gutter-horizontal: 80px
		--gutter-vertical: 60px
	@media screen and (max-width: $xl)
		--gutter-horizontal: 60px
		--gutter-vertical: 40px
	@media screen and (max-width: $md)
		--gutter-horizontal: 20px
		--gutter-vertical: 20px
