/* ======================================================================== */
/* 17. maskReveal */
/* ======================================================================== */
.mask-reveal
  position: relative
  overflow: hidden
.mask-reveal__layer
  display: block
  // position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden
