/* ======================================================================== */
/* 8. cardPortfolio */
/* ======================================================================== */
.card-portfolio
	overflow: hidden
	border-radius: 4px
.card-portfolio__content
  // padding: 4em
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
.card-portfolio__content_small
  padding: 60px
.card-portfolio__wrapper-img
  img
    transform: scale(1.03)

// @media screen and (max-width: $lg)
//   .card-portfolio__content
//     padding: 3em
