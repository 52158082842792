/* ======================================================================== */
/* 108. sectionList */
/* ======================================================================== */
.section-list
.section-list__item
	display: flex
	align-items: center
	margin-bottom: 2em
	&:last-child
		margin-bottom: 0
.section-list__counter
	position: relative
	display: flex
	align-items: center
	justify-content: center
	@include fluid('width', 50, 72)
	@include fluid('height', 50, 72)
	// border-radius: 50%
	// border: 1px solid $color-border-dark
	flex-shrink: 0
	margin-right: 1em
	.svg-circle
		stroke-width: 1px
		stroke: $color-border-dark
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
.section-list__wrapper-circle
	position: absolute
	top: 0
	le: 0
	width: 100%
	height: 100%
.section-list__content
	width: 100%
	ul, li
		padding-left: 0 !important
	li:last-child
		margin-bottom: 0
