/* ======================================================================== */
/* 70. gallery */
/* ======================================================================== */
.gallery
	margin-top: 2em
	margin-bottom: 2em

.gallery-item
	display: inline-block
	text-align: center
	vertical-align: top
	width: 100%

.gallery-columns-2 .gallery-item
	max-width: 50%

.gallery-columns-3 .gallery-item
	max-width: 33.33%

.gallery-columns-4 .gallery-item
	max-width: 25%

.gallery-columns-5 .gallery-item
	max-width: 20%

.gallery-columns-6 .gallery-item
	max-width: 16.66%

.gallery-columns-7 .gallery-item
	max-width: 14.28%

.gallery-columns-8 .gallery-item
	max-width: 12.5%

.gallery-columns-9 .gallery-item
	max-width: 11.11%

.gallery-caption
	display: block
