/* ======================================================================== */
/* 24. fluidPaddings */
/* ======================================================================== */
// Padding All Sides
.p-xsmall
	@include fluid('padding', $distance-min-xsmall, $distance-max-xsmall)
.p-small
	@include fluid('padding', $distance-min-small, $distance-max-small)
.p-medium
	@include fluid('padding', $distance-min-medium, $distance-max-medium)
.p-large
	@include fluid('padding', $distance-min-large, $distance-max-large)
.p-xlarge
	@include fluid('padding', $distance-min-xlarge, $distance-max-xlarge)

// Padding Vertical
.py-xsmall
	@include fluid('padding-top', $distance-min-xsmall, $distance-max-xsmall)
	@include fluid('padding-bottom', $distance-min-xsmall, $distance-max-xsmall)
.py-small
	@include fluid('padding-top', $distance-min-small, $distance-max-small)
	@include fluid('padding-bottom', $distance-min-small, $distance-max-small)
.py-medium
	@include fluid('padding-top', $distance-min-medium, $distance-max-medium)
	@include fluid('padding-bottom', $distance-min-medium, $distance-max-medium)
.py-large
	@include fluid('padding-top', $distance-min-large, $distance-max-large)
	@include fluid('padding-bottom', $distance-min-large, $distance-max-large)
.py-xlarge
	@include fluid('padding-top', $distance-min-xlarge, $distance-max-xlarge)
	@include fluid('padding-bottom', $distance-min-xlarge, $distance-max-xlarge)

// Padding Horizontal
.px-xsmall
	@include fluid('padding-left', $distance-min-xsmall, $distance-max-xsmall)
	@include fluid('padding-right', $distance-min-xsmall, $distance-max-xsmall)
.px-small
	@include fluid('padding-left', $distance-min-small, $distance-max-small)
	@include fluid('padding-right', $distance-min-small, $distance-max-small)
.px-medium
	@include fluid('padding-left', $distance-min-medium, $distance-max-medium)
	@include fluid('padding-right', $distance-min-medium, $distance-max-medium)
.px-large
	@include fluid('padding-left', $distance-min-large, $distance-max-large)
	@include fluid('padding-right', $distance-min-large, $distance-max-large)
.px-xlarge
	@include fluid('padding-left', $distance-min-xlarge, $distance-max-xlarge)
	@include fluid('padding-right', $distance-min-xlarge, $distance-max-xlarge)

// Padding Top
.pt-xsmall
	@include fluid('padding-top', $distance-min-xsmall, $distance-max-xsmall)
.pt-small
	@include fluid('padding-top', $distance-min-small, $distance-max-small)
.pt-medium
	@include fluid('padding-top', $distance-min-medium, $distance-max-medium)
.pt-large
	@include fluid('padding-top', $distance-min-large, $distance-max-large)
.pt-xlarge
	@include fluid('padding-top', $distance-min-xlarge, $distance-max-xlarge)

// Padding Right
.pr-xsmall
	@include fluid('padding-right', $distance-min-xsmall, $distance-max-xsmall)
.pr-small
	@include fluid('padding-right', $distance-min-small, $distance-max-small)
.pr-medium
	@include fluid('padding-right', $distance-min-medium, $distance-max-medium)
.pr-large
	@include fluid('padding-right', $distance-min-large, $distance-max-large)
.pr-xlarge
	@include fluid('padding-right', $distance-min-xlarge, $distance-max-xlarge)

// Padding Bottom
.pb-xsmall
	@include fluid('padding-bottom', $distance-min-xsmall, $distance-max-xsmall)
.pb-small
	@include fluid('padding-bottom', $distance-min-small, $distance-max-small)
.pb-medium
	@include fluid('padding-bottom', $distance-min-medium, $distance-max-medium)
.pb-large
	@include fluid('padding-bottom', $distance-min-large, $distance-max-large)
.pb-xlarge
	@include fluid('padding-bottom', $distance-min-xlarge, $distance-max-xlarge)

// Padding Left
.pl-xsmall
	@include fluid('padding-left', $distance-min-xsmall, $distance-max-xsmall)
.pl-small
	@include fluid('padding-left', $distance-min-small, $distance-max-small)
.pl-medium
	@include fluid('padding-left', $distance-min-medium, $distance-max-medium)
.pl-large
	@include fluid('padding-left', $distance-min-large, $distance-max-large)
.pl-xlarge
	@include fluid('padding-left', $distance-min-xlarge, $distance-max-xlarge)
