/* ======================================================================== */
/* 20. figureImage */
/* ======================================================================== */
.figure-image
	display: block
	margin-bottom: 0
.figure-image__wrapper-img
	position: relative
	overflow: hidden
.figure-image__caption
	display: inline-block !important
