/* ======================================================================== */
/* 101. sectionBlog */
/* ======================================================================== */
.section-blog__wrapper-post
	&:last-child
		margin-bottom: 0 !important

@media screen and (max-width: $xs)
	.section-blog
		.section-offset__content
			transform: none !important
